
  <div class="card">
    <div class="card-body">
      <h3>{{titulo}}</h3>
      <div style="display: block">
        <canvas baseChart
          [data]="doughnutChartData"
          [labels]="doughnutChartLabels"
          [colors]="colors"
          chartType="doughnut">
        </canvas>
      </div>
    </div>
  </div>

