<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar" style="background-image:url(./assets/images/background/login-register.jpg);">
  <div class="login-box card">
    <div class="card-body">
      <form class="form-horizontal form-material" id="loginform" [formGroup]="loginForm" (submit)="login()" autocomplete="off">
				<a href="javascript:void(0)" class="text-center db logoPrincipal"><img src="./assets/images/logo-icon.png" alt="Home" /></a>

				<div class="form-group m-t-10">
          <div class="col-sm-12 text-center">
            <strong> Servicio YUME </strong> Organiza TUS FINANZAS, dale manejo a tus ingresos y controla tus gastos y prestamos. <strong >¡Te ayudamos a ordenar tu dinero!</strong>.
          </div>
        </div>

        <div class="form-group m-t-40">
          <div class="col-xs-12">
            <input class="form-control" type="email" formControlName="email" placeholder="Correo Electrónico">
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <input class="form-control" type="password" formControlName="password" placeholder="Contraseña">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <div class="checkbox checkbox-primary pull-left p-t-0">
              <input id="checkbox-signup" type="checkbox" formControlName="remember" class="filled-in chk-col-light-blue">
              <label for="checkbox-signup"> Recuérdame </label>
            </div>
            <a routerLink="/reset-password" id="to-recover" class="text-dark pull-right"><i class="fa fa-lock m-r-5"></i> Olvidé Contraseña?</a>
          </div>
        </div>
        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            <button *ngIf="!esperando" class="btn btn-info btn-lg btn-block text-uppercase btn-rounded btnYume" type="submit">Iniciar sesión</button>
          </div>
        </div>
        <div class="contBtnLogin">
          <div *ngIf="esperando" class="notify"> <span class="heartbit"></span> <span class="point"></span> </div>
        </div>
        <div class="col-xs-12 text-danger">
          <p *ngIf="campoNoValido('email')">*The E-mail is required and valid.</p>
          <p *ngIf="campoNoValido('password')">*The Password is required and valid.</p>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
            <div class="social">
              <a href="javascript:void(0)" class="btn  btn-facebook" data-toggle="tooltip" title="Login with Facebook">
                <i aria-hidden="true" class="fa fa-facebook"></i>
              </a>
              &nbsp;
              <a href="javascript:void(0)" class="btn btn-googleplus" data-toggle="tooltip" title="Login with Google">
                <i aria-hidden="true" class="fa fa-google-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="form-group m-b-0">
          <div class="col-sm-12 text-center">
            No tengo una cuenta? <a routerLink="/register" class="text-info m-l-5"><b>Regístrate</b></a>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
