<div *ngIf=" ingresos.length == 0 " class="row">
  <div class="col-lg-12">
    <div class="alert alert-warning">
      <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button>
      <h3 class="text-warning"><i class="fa fa-exclamation-triangle"></i> Advertencia</h3> En este momento no hay ingresos creados, por favor cree el primero!.
      <br><br>
      <button type="button" (click)="goBack()" class="btn waves-effect waves-light btn-rounded btn-danger">Volver</button>
    </div>
  </div>
</div>

<!-- Tabla con los ingresos -->
<div class="row el-element-overlay">
  <div class="col-md-12">
    <h4 class="card-title">Información de los ingresos</h4>
    <h6 class="card-subtitle m-b-20 text-muted">A continuación puede ver la lista de los ingresos.</h6>
  </div>
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <!-- Formulario para filtrar por fechas -->
        <h4 class="card-title">Filtre los ingresos por fechas</h4>
        <span class="spanTotal">Total ingresos: {{sumaIngresos | currency: 'USD' : 'symbol' : '1.0-0'}}</span>
        <form [formGroup]="formFiltroFechas" (submit)="consultarFechasIngresos()" class="form-material m-t-40">
          <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-4">
              <div class="form-group">
                <label>Fecha inicial</label>
                <input type="date" formControlName="fechaInicio" class="form-control">
              </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-4">
              <div class="form-group">
                <label>Fecha final</label>
                <input type="date" formControlName="fechaFin" class="form-control">
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-4">
              <button type="submit" class="btn waves-effect waves-light btn-rounded btn-warning m-t-30 m-b-20">Filtrar Ingreso</button>
            </div>
          </div>
        </form>
        
        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="dataSource">
        
            <ng-container matColumnDef="id_ingre">
              <th mat-header-cell *matHeaderCellDef>Ingreso No. </th>
              <td mat-cell *matCellDef="let element"> {{element.id_ingre}} </td>
            </ng-container>
        
            <ng-container matColumnDef="valor_ingre">
              <th mat-header-cell *matHeaderCellDef> <div>Valor</div> </th>
              <td mat-cell *matCellDef="let element"> 
                <div>{{element.valor_ingre | currency: 'USD' : 'symbol' : '1.0-0'}}</div> 
              </td>
            </ng-container>
        
            <ng-container matColumnDef="pago_credito_ingre">
              <th mat-header-cell *matHeaderCellDef> <div>Tipo</div> </th>
              <td mat-cell *matCellDef="let element"> 
                <div>
                  <small 
                    *ngIf="element.pago_credito_ingre == 0; else isCredit" 
                    class="label label-warning tipoIngre">Ingreso
                  </small>
                  <ng-template #isCredit>
                    <small class="label label-primary tipoIngre">Crédito</small>
                  </ng-template>
                </div>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="comentario_ingre">
              <th mat-header-cell *matHeaderCellDef> 
                <div style="width:160px;">Comentario</div> 
              </th>
              <td mat-cell *matCellDef="let element">
                <div style="width:160px;">{{element.comentario_ingre}}</div>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="fecha_ingre">
              <th mat-header-cell *matHeaderCellDef> <div>Fecha</div> </th>
              <td mat-cell *matCellDef="let element"> 
                <div>{{element.fecha_ingre | date: 'dd/MM/yyyy'}} </div>
              </td>
            </ng-container>
        
            <ng-container matColumnDef="acciones">
              <th mat-header-cell *matHeaderCellDef> <div style="margin-left: 25px;">Acciones</div> </th>
              <td mat-cell *matCellDef="let element"> 
                <div class="td-actions">
                  <span (click)="viewDetailIngre(element.id_ingre)" class="cursor label label-info" title="Ver">
                    <i class="ti-eye"></i>
                  </span>
                  &nbsp;
                  <span (click)="navegarEditIngreso(element.id_ingre)" class="cursor label label-success" title="Editar"><i class="ti-pencil-alt"></i></span>
                  &nbsp;
                  <span (click)="EliminarIngreso(element.id_ingre)" class="cursor label label-danger" title="Eliminar"><i class="ti-trash"></i></span>
                </div>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        
          <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                         showFirstLastButtons
                         aria-label="Select page of periodic elements">
          </mat-paginator>
        </div>
        
        <br>
        <div class="col-lg-12">
          <button type="button" routerLink="/dashboard/ingresos/crear-ingreso" class="btn waves-effect waves-light btn-rounded btn-warning">Crear Ingresos</button>
          &nbsp;
          <button
            *ngIf="isFilterDate"
            (click)="cleanFilters()"
            type="button" 
            class="btn waves-effect waves-light btn-rounded label-success" style="color: white;">Limpiar filtros
          </button>
          &nbsp;
          <button type="button" (click)="goBack()" class="btn waves-effect waves-light btn-rounded btn-danger">Volver</button>
        </div>
      </div>
    </div>
  </div>
</div>