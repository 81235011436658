<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<aside class="left-sidebar">
  <!-- Sidebar scroll-->
  <div class="scroll-sidebar">
    <!-- Sidebar navigation-->
    <nav class="sidebar-nav">
      <ul id="sidebarnav">
        <li class="user-profile">
          <a
            class="has-arrow waves-effect waves-dark"
            href="#"
            aria-expanded="false"
          >
            <img
              src="https://files.gtsoftweb.com/images/{{ dataInfoUser.avatar }}"
              alt="user"
            />
            <span class="hide-menu"
              >{{ dataInfoUser.nombre | slice: 0:18
              }}{{ dataInfoUser.nombre.length > 18 ? "..." : "" }}
            </span>
          </a>
          <ul aria-expanded="false" class="collapse">
            <li>
              <a (click)="closeMenu()" routerLink="./perfil">Mi Cuenta </a>
            </li>
            <li>
              <a (click)="closeMenu()" routerLink="./datos-finanzas"
                >Mis Balances</a
              >
            </li>
            <li>
              <a class="cursorSidebar" (click)="cerrarSesion(); closeMenu()"
                >Cerrar Sesión
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-devider"></li>
        <li class="nav-small-cap">PERSONAL</li>
        <li>
          <a
            class="has-arrow waves-effect waves-dark"
            routerLink="/"
            (click)="closeMenu()"
            >
            <i class="mdi mdi-gauge"></i>
            <span class="hide-menu">Dashboard
              <span class="label label-rouded label-themecolor pull-right" *ngIf="webSocketSrv.acountNoti>0">
                {{webSocketSrv.acountNoti}}
              </span>
            </span>
          </a>
        </li>
        <li>
          <a
            class="has-arrow waves-effect waves-dark"
            href="#"
            aria-expanded="false"
            ><i class="mdi mdi-account-multiple"></i
            ><span class="hide-menu">Zona Clientes</span></a
          >
          <ul aria-expanded="false" class="collapse">
            <li>
              <a (click)="closeMenu()" routerLink="clientes/lista-clientes"
                >Lista clientes</a
              >
            </li>
            <li>
              <a (click)="closeMenu()" routerLink="clientes/crear-cliente"
                >Crear cliente</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            class="has-arrow waves-effect waves-dark"
            href="#"
            aria-expanded="false"
            ><i class="mdi mdi-wallet-giftcard"></i
            ><span class="hide-menu">Zona de Pago</span></a
          >
          <ul aria-expanded="false" class="collapse">
            <li>
              <a (click)="closeMenu()" routerLink="creditos/lista-creditos"
                >Lista de créditos</a
              >
            </li>
            <li>
              <a (click)="closeMenu()" routerLink="pagos/lista-pagos"
                >Pagos realizados</a
              >
            </li>
          </ul>
        </li>
        <li>
          <a
            class="has-arrow waves-effect waves-dark"
            href="#"
            aria-expanded="false"
            ><i class="mdi mdi-wallet"></i
            ><span class="hide-menu">Finanzas</span></a
          >
          <ul aria-expanded="false" class="collapse">
            <li>
              <a (click)="closeMenu()" routerLink="datos-finanzas"
                >Mis Finanzas</a
              >
            </li>
            <li><a (click)="closeMenu()" routerLink="ingresos">Ingresos</a></li>
            <li><a (click)="closeMenu()" routerLink="egresos">Egresos</a></li>
          </ul>
        </li>
        <li *ngIf="dataInfoUser.admin == 'Y'">
          <a
            class="has-arrow waves-effect waves-dark"
            href="#"
            aria-expanded="false"
            ><svg
              style="margin-right:5px;margin-bottom:-5px;"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M11.5 18.5c0-1.1.3-2.1.7-3H12c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5c0 .2 0 .4-.1.5c.8-.3 1.6-.5 2.6-.5c.5 0 1 .1 1.5.2V12c0-.3 0-.7-.1-1l2.1-1.6c.2-.2.2-.4.1-.6l-2-3.5c-.1-.3-.3-.3-.6-.3l-2.5 1c-.5-.4-1.1-.7-1.7-1l-.4-2.7c.1-.1-.2-.3-.4-.3h-4c-.2 0-.5.2-.5.4l-.4 2.7c-.6.2-1.1.6-1.7.9L5 5c-.3 0-.5 0-.7.3l-2 3.5c-.1.2 0 .4.2.6L4.6 11c0 .3-.1.7-.1 1s0 .7.1 1l-2.1 1.7c-.2.2-.2.4-.1.6l2 3.5c.1.2.3.2.6.2l2.5-1c.5.4 1.1.7 1.7 1l.4 2.7c0 .2.2.4.5.4h2.5c-.7-1.1-1.1-2.3-1.1-3.6m6.5-4V13l-2.2 2.2l2.2 2.2V16c1.4 0 2.5 1.1 2.5 2.5c0 .4-.1.8-.3 1.1l1.1 1.1c1.2-1.8.7-4.3-1.1-5.5c-.6-.5-1.4-.7-2.2-.7m0 6.5c-1.4 0-2.5-1.1-2.5-2.5c0-.4.1-.8.3-1.1l-1.1-1.1c-1.2 1.8-.7 4.3 1.1 5.5c.7.4 1.4.7 2.2.7V24l2.2-2.2l-2.2-2.3V21Z"
              />
            </svg>
            <span class="hide-menu">Mis Proyectos</span></a
          >
          <ul aria-expanded="false" class="collapse">
            <li>
              <a (click)="closeMenu()" routerLink="proyectos"
                >Lista de proyectos</a
              >
            </li>
            <li>
              <a (click)="closeMenu()" routerLink="proyectos/crear-proyectos"
                >Agregar proyectos</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- End Sidebar navigation -->
  </div>
  <!-- End Sidebar scroll-->
</aside>
<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
