<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="row page-titles">
		<div class="col-md-5 align-self-center">
				<h3 class="text-themecolor">{{titulo}}</h3>
		</div>
		<div class="col-md-7 align-self-center">
				<ol class="breadcrumb">
						<li class="breadcrumb-item">
							<a routerLink="/">Dashboard</a>
						</li>
						<li class="breadcrumb-item">Personal</li>
						<li class="breadcrumb-item active">{{titulo}}</li>
				</ol>
		</div>
		<!-- <div>
			<button class="right-side-toggle waves-effect waves-light btn-inverse btn btn-circle btn-sm pull-right m-l-10"><i class="ti-settings text-white"></i></button>
		</div> -->
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->