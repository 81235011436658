
<section id="wrapper">
  <div class="login-register" style="background-image:url(../assets/images/background/login-register.jpg);">
    <div class="login-box card">
      <div class="card-body">
        <form class="form-horizontal form-material" [formGroup]="formNewPassword" (submit)="submitNewPassword()">
          <h3 class="box-title m-b-20">Nueva contraseña</h3>
          <div class="form-group ">
            <div class="col-xs-12">
              <input class="form-control" type="password" formControlName="pass" placeholder="Nueva contraseña">
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <input class="form-control" type="password" formControlName="confirmPass" placeholder="Confirme la contraseña">
            </div>
          </div>
          <div class="form-group text-center p-b-10">
            <div class="col-xs-12">
              <button 
                class="btn btn-info btn-lg btn-block text-uppercase btn-rounded btnReset"
                *ngIf="!awaitResp"
                [disabled]="formNewPassword.invalid"
                type="submit">Enviar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>