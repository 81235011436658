<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar" style="background-image:url(./assets/images/background/login-register.jpg);">
  <div class="login-box card">
    <div class="card-body">

      <form [formGroup]="registerForm" (submit)="registrarUsuario()" class="form-horizontal form-material">
        <a href="javascript:void(0)" class="text-center db logoPrincipal"><img src="./assets/images/logo-icon.png" alt="Home" /></a>
        <h3 class="box-title m-t-10 m-b-0">Regístrate ahora</h3><small>Crea tu cuenta en el siguiente formulario</small>
        <div class="form-group m-t-20">
          <div class="col-xs-12">
            <input class="form-control" type="text" formControlName="nombre" placeholder="Nombre completo*">
          </div>
        </div>
        <div class="form-group ">
          <div class="col-xs-12">
            <input class="form-control" type="text" formControlName="email" placeholder="Correo Electrónico*">
          </div>
        </div>
        <div class="form-group ">
          <div class="col-xs-12">
            <label for="">Género</label>
            <select formControlName="genero" class="form-control">
              <option value="M" >Masculino</option>
              <option value="F" >Femenino</option>
              <option value="O" >Otro</option>
            </select>
          </div>
        </div>
        <div class="form-group ">
          <div class="col-xs-12">
            <input class="form-control" type="password" formControlName="password" placeholder="Contraseña">
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <input class="form-control" type="password" formControlName="password2" placeholder="Confirmar Contraseña">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-12">
            <div class="checkbox checkbox-primary p-t-0">
              <input id="checkbox-signup" type="checkbox" formControlName="terminos">
              <label for="checkbox-signup" class="terminos"> Estoy de acuerdo con los <a href="#" >Términos y Condiciones</a></label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col text-danger">
            <p *ngIf="campoNoValido('nombre')" >*El nombre es obligatorio</p>
            <p *ngIf="campoNoValido('email')" >*El E-mail es obligatorio y válido.</p>
            <p *ngIf="campoNoValido('genero')" >*El Género es obligatorio.</p>
            <p *ngIf="contrasenasNoValidas()" >*Las contraseñas deben de ser iguales Min 8 caracteres.</p>
            <p *ngIf="aceptaTerminos()" >*Debe aceptar los términos de uso..</p>
          </div>
        </div>

        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            <button class="btn btn-info btn-lg btn-block text-uppercase btn-rounded btnYume" type="submit">Regístrate</button>
          </div>
        </div>
        <div class="form-group m-b-0">
          <div class="col-sm-12 text-center">
            <p>Ya tienes una cuenta? <a routerLink="/login" class="text-info m-l-5"><b>Iniciar Sesión</b></a></p>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->
