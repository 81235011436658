
<section id="wrapper">
  <div class="login-register" style="background-image:url(../assets/images/background/login-register.jpg);">
    <div class="login-box card">
      <div class="card-body">
        <form class="form-horizontal form-material" [formGroup]="formResetPassword" (submit)="submitReset()">
          <h3 class="box-title m-b-20">Recuperar contraseña</h3>
          <div class="form-group ">
            <div class="col-xs-12">
              <input class="form-control" type="text" formControlName="name" placeholder="Nombre">
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <input class="form-control" type="text" formControlName="email" placeholder="Email">
            </div>
          </div>
          <div class="form-group text-center p-b-10">
            <div class="col-xs-12">
              <button 
                class="btn btn-info btn-lg btn-block text-uppercase btn-rounded btnReset"
                [disabled]="formResetPassword.invalid"
                *ngIf="!awaitResp"
                type="submit">Enviar</button>
              <button routerLink="/login" class="btn btn-danger btn-lg btn-block text-uppercase btn-rounded btnReset" type="button">Volver</button>
            </div>
            <br>
            <p *ngIf="txtSuccess" class="text-center">Bien hecho! Se envió un correo a <strong>{{emailUser}}</strong> por favor verifique para recuperar su contraseña.</p>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>